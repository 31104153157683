/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import React, { useState } from 'react';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import goldCardFrente from '../../assets/gold-frente.png';
import goldCardVerso from '../../assets/gold-verso.png';
import blackCardFrente from '../../assets/black-frente.png';
import blackCardVerso from '../../assets/black-verso.png';
import platinumCardFrente from '../../assets/platinum-frente.png';
import platinumCardVerso from '../../assets/platinum-verso.png';
import { maskCPF, maskRegistration } from '../../helpers/inputMasks.js';
import showCompactName from '../../helpers/showCompactName';
import CardPDF from './CardPDF.jsx';
import ReceiveCardModal from './ReceiveCardModal';

export type PrintedCardProps = {
  id: number,
  printedAt: string,
  isSecondCopy: boolean,
  pickedUp: boolean,
  pickedUpDate: string,
  observation: string
}

export interface ICard {
    id: string;
    uuid: any,
    registration: string;
    name: string;
    isHolder: boolean,
    cardname: string | null;
    cpf: string;
    expiration: string;
    birth: string;
    modality: string;
    category: string;
    front: boolean;
    setCardSide: () => void;
    hideCpfOnCard?: boolean;
    printedCards: PrintedCardProps[]
}

export default function Card({
  id, registration, name, cpf, expiration, birth, modality, category, front, setCardSide, cardname, hideCpfOnCard, printedCards, isHolder, uuid,
}: ICard) {
  function selectBackCardcolor() {
    // if (category === 'GOLD') {
    //   return goldCardVerso;
    // }
    // if (category === 'BLACK') {
    //   return blackCardVerso;
    // }
    return platinumCardVerso;
  }

  function selectFrontCardColor() {
    // if (category === 'GOLD') {
    //   return goldCardFrente;
    // }
    // if (category === 'BLACK') {
    //   return blackCardFrente;
    // }
    return platinumCardFrente;
  }

  const maskedRegistration = maskRegistration(registration);
  const compactName = showCompactName(name);
  const maskedCPF = maskCPF(cpf);
  if (!front) {
    return (
      <>
        <div className="btn-wrapper">
          <button type="button" className={front ? 'btn-active' : 'btn-inactive'} onClick={() => setCardSide()}>Frente</button>
          <button type="button" className={front ? 'btn-inactive' : 'btn-active'} onClick={() => setCardSide()}>Verso</button>
        </div>
        <div className="col-12 card-background" id={id}>
          <img src={selectBackCardcolor()} alt="" className="rounded" />
          <div className="card-letters-wrapper">
            <div className="card-letters-left">
              <div>
                <p>{maskedRegistration}</p>
              </div>
              <div>
                <p className="card-letters-title">Nome do beneficiário</p>
                <p>{cardname || compactName}</p>
              </div>
              <div>
                <p className="card-letters-title">cpf</p>
                {hideCpfOnCard ? <p>***.***.***-**</p> : <p>{maskedCPF}</p>}
              </div>
              {/* <div>
                <p className="card-letters-title">Validade</p>
                <p>{expiration}</p>
              </div> */}
            </div>
            <div className="card-letters-right">
              <div />
              <div>
                <p className="card-letters-title">Data Nasc.</p>
                <p>{birth}</p>
              </div>
              <div>
                <p className="card-letters-title">Modalidade</p>
                <p>{modality}</p>
              </div>
              <div>
                <p className="card-letters-title">Categoria</p>
                <p>{category}</p>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="download-card-btn"
          data-bs-toggle="modal"
          data-bs-target="#downloadCardModal"
        >
          Baixar cartão
        </button>
        <button
          type="button"
          className="download-card-btn-secondary"
          data-bs-toggle="modal"
          data-bs-target="#receivecard"
        >
          Entrega do cartão
        </button>
        <ReceiveCardModal isHolder={isHolder} uuid={uuid} id={uuid} receivedCard={printedCards.length > 0 ? printedCards[0] : null} />
        <CardPDF
          cardFrontSrc={selectFrontCardColor()}
          cardBackSrc={selectBackCardcolor()}
          registration={maskedRegistration}
          name={cardname || compactName}
          cpf={hideCpfOnCard ? '***.***.***-**' : maskedCPF}
          expiration={expiration}
          birth={birth}
          modality={modality}
          category={category}
        />
      </>
    );
  }
  return (
    <>
      <div className="btn-wrapper">
        <button type="button" className={front ? 'btn-active' : 'btn-inactive'} onClick={() => setCardSide()}>Frente</button>
        <button type="button" className={front ? 'btn-inactive' : 'btn-active'} onClick={() => setCardSide()}>Verso</button>
      </div>
      <div className="col-12 card-background">
        <img src={selectFrontCardColor()} alt="" />
      </div>
      <button type="button" className="download-card-btn">Baixar cartão</button>
    </>
  );
}
